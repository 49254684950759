<!-- 合同列表 -->
<template>
  <div>
    <blockTitle title="合同列表"></blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <AppTable
            ref="AppTable"
            controller="agreement"
            :tableColumns="tableColumns"
            @on-handleSelection-change="onHandleSelectionChange"
            :defaultGetData="true"
        >
          <template #quick-operator-box>
            <el-button
                class="ml-4"
                type="primary"
                size="mini"
                @click="createAgreement"
            >创建合同
            </el-button>
            <el-button
                v-if="multipleSelection.length > 0"
                type="danger"
                size="mini"
                @click="deleteAgreement"
            >
              删除合同
            </el-button>
          </template>
          <template #operator="{ row }">
            <el-button size="small" @click="toAgreementDetails(row)"
            >查看明细
            </el-button>
          </template>
        </AppTable>
      </div>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      tableColumns: [
        {
          label: '#',
          type: 'selection'
        },
        {
          label: '合同名称',
          prop: 'agreementName',
          search: true
        },
        {
          label: '授权数量',
          prop: 'authNum'
        },
        {
          label: '合同时间',
          prop: 'agreementDtm'
        },
        {
          label: '合同金额',
          prop: 'money'
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ],
      multipleSelection: []
    }
  },
  methods: {
    toAgreementDetails (item) {
      this.$router.push({
        name: 'agreementDetails',
        query: {
          id: item.id
        }
      })
    },
    createAgreement () {
      this.$router.push({ name: 'agreementEdit' })
    },
    onHandleSelectionChange (list) {
      this.multipleSelection = list
    },
    deleteAgreement () {
      const ids = this.multipleSelection.map(item => item.id)
      this.$api.deleteBatch('agreement', ids).then(() => {
        this.$refs.AppTable.clearSelection()
        this.reGetData()
      })
    },
    reGetData () {
      this.$refs.AppTable.reGetData()
    }
  },
  mounted () {
  }
}
</script>
